import {
  Box, Button, Flex, Heading, Image, Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { Else, If, Then } from 'react-if';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import ViewPOIcon from '../../assets/icons/view-po.svg';
import { studioServerApi } from 'api/studioServerApi';
import StudiumSpinner from 'components/StudiumSpinner';
import { useApiQuery } from 'hooks/useApiQuery';
import useTenantContext from 'hooks/useTenantContext';

export default function InvoicePaid(): JSX.Element {
  const navigate = useNavigate();
  const { logoSrc } = useTenantContext();
  const hasProcessedInvoice = useRef(false);
  const {
    contactIdentifier, projectInvoiceIdentifier, checkoutSessionId,
  } = useParams();

  if (!contactIdentifier || !projectInvoiceIdentifier) {
    throw new Error('Please ensure the URL format is correct.');
  }

  const pdfUrl = `/api/downloads/project-invoice/${projectInvoiceIdentifier}`;

  const { mutate: processPaidInvoice } = useMutation({
    mutationFn: () => studioServerApi.POST('/api/project-invoices/process-paid-invoice', {
      body: {
        CheckoutSessionId: checkoutSessionId,
        ContactIdentifier: contactIdentifier,
        ProjectInvoiceIdentifier: projectInvoiceIdentifier,
      },
    }),
    onSuccess: () => navigate(`/portal/pay-invoice/paid/${contactIdentifier}/${projectInvoiceIdentifier}`),
    onError: (err) => {
      if (err.message.match(/paid/gi) && err.message.match(/paid/gi)!.length > 0) {
        navigate(`/portal/pay-invoice/paid/${contactIdentifier}/${projectInvoiceIdentifier}`);
      }
    },
  });

  const { apiResult: projectInvoice } = useApiQuery({
    queryKey: [`/api/project-invoices/${projectInvoiceIdentifier}`],
    queryFn: () => studioServerApi.GET('/api/project-invoices/{projectInvoiceIdentifier}', { params: { path: { projectInvoiceIdentifier } } }),
    enabled: !checkoutSessionId,
  });

  useEffect(() => {
    if (!hasProcessedInvoice.current && checkoutSessionId) {
      hasProcessedInvoice.current = true;
      processPaidInvoice();
    }
  }, [checkoutSessionId, processPaidInvoice]);

  return (
    <If condition={!projectInvoice}>
      <Then>
        <StudiumSpinner />
      </Then>
      <Else>
        <Flex
          padding='20px'
          direction='column'
          align='center'
          w='400px'
          mx='auto'
          mt='50px'
        >
          <Box width='400px' padding='20px'>
            <Flex direction='column' gap='25px'>
              <Flex justifyContent='center' mb='15px'>
                <Image src={logoSrc} width='300px' alt='Tenant Logo' />
              </Flex>
              <Heading
                fontSize='24px'
                textAlign='center'
              >
                Invoice Paid
              </Heading>
              <Flex>
                <Text mr='7px' fontSize='18px'>Invoice Number:</Text>
                <Text fontWeight='900' fontSize='18px'>{projectInvoice?.data?.InvoiceNumber}</Text>
              </Flex>
              <Button variant='back' as={Link} to={pdfUrl} target='_blank' aria-label='View paid invoice'>
                <Image w='15px' h='15px' mr='7px' src={ViewPOIcon} alt='View Invoice Icon' />
                View Invoice
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Else>
    </If>
  );
}
