import {
  Button,
  Flex,
  Heading,
  Img,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useMemo, useState } from 'react';
import {
  Case,
  Default,
  Switch, When,
} from 'react-if';
import { useParams } from 'react-router';

import { ArchiveOverview } from './ArchiveOverview';
import ArchiveIcon from 'assets/icons/archive.svg';
import DownloadIcon from 'assets/icons/download.svg';
import RestoreIcon from 'assets/icons/restore.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AsyncButton from 'components/form/AsyncButton';
import { TabWithIcon } from 'components/TabWithIcon';
import useAxios from 'hooks/useAxios';
import { useAzureDownload } from 'hooks/useAzureDownload';
import { ActivityIcon } from 'pages/transfers/files-page/components/ActivityIcon';
import { FileIcon } from 'pages/transfers/files-page/components/FileIcon';
import { FileViewTabs } from 'pages/transfers/files-page/components/FileViewTabs';
import FileListContext from 'pages/transfers/files-page/FileListContext';
import { ProjectOverview } from 'pages/transfers/project-overview-page/components/ProjectOverview';
import { ActivitiesTable } from 'pages/transfers/projects-page/ActivitiesTable';
import {
  Files, TransferRestoreRequests, Transfers,
} from 'types/api';
import { getArchivesStatus } from 'utils/status';

export default function ArchiveFilesPage(): JSX.Element {
  const { archiveId } = useParams();
  const [transferRequest] = useAxios<Transfers>(`/file-api/transfers/${archiveId}`);
  const [transferFileListRequest, fetchTransferFileList] = useAxios<Files[]>(`/file-api/transfers/${archiveId}/file-list`);
  const transfer = transferRequest.data;

  const transferStatus = useMemo(() => {
    if (!transfer) return;
    return getArchivesStatus(transfer.Restoring, transfer.DateRestored);
  }, [transfer]);

  const fileListContext = useMemo(() => ({
    isReadOnly: transferStatus !== 'Restored',
    fileList: transferFileListRequest.data || [],
    fetchFileList: fetchTransferFileList,
    isArchive: true,
  }), [fetchTransferFileList, transferFileListRequest.data, transferStatus]);

  const [tabIndex, setTabIndex] = useState(0);

  const toast = useToast();

  const {
    downloadBlobs, smsModalProps, chromeSecurityNotificationProps, chromeDownloadModalProps,
  } = useAzureDownload();
  async function downloadBlob(id: string, artist: string | undefined): Promise<void> {
    const res = await axios.get<Files[]>(`/file-api/transfers/${id}/file-list`);
    await downloadBlobs({ blobs: res.data, artist });
  }

  async function restoreArchive(): Promise<void> {
    const restoringArchiveRequest = await axios.post<TransferRestoreRequests>(`/file-api/archives/${archiveId}/restore`);
    toast({
      title: restoringArchiveRequest.data.Fulfilled ? 'Archive is already restored' : 'Archive is restoring',
      status: restoringArchiveRequest.data.Fulfilled ? 'warning' : 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }

  return (
    <AsyncWrapper requests={[transferRequest, transferFileListRequest, { loading: !transfer }]}>
      <FileListContext.Provider value={fileListContext}>
        <Flex direction='column' gap='50px' width='100%' justifyContent='center'>
          <Flex direction='row' justifyContent='space-between'>
            <Flex height='30px' align='start' gap='10px'>
              <Img src={ArchiveIcon} w='20px' h='20px' />
              <Heading fontWeight='700' fontSize='20px'>Archive #{archiveId}</Heading>
            </Flex>
            <Flex align='end'>
              <Switch>
                <Case condition={transferStatus === 'Restored'}>
                  <AsyncButton
                    spinAfterPromiseResolvedSuccess={false}
                    onClick={() => downloadBlob(archiveId!, transfer?.Project.ArtistIdentifier || undefined)} // come back to this because it might be wrong?
                    leftIcon={<Img src={DownloadIcon} />}
                    padding='15px 25px'
                  >
                    Download
                  </AsyncButton>
                </Case>
                <Case condition={transferStatus === 'Restoring'}>
                  <Button
                    isLoading
                    padding='15px 25px'
                    loadingText='Restoring'
                  />
                </Case>
                <Default>
                  {/* transferStatus === 'Archived' */}
                  <AsyncButton
                    spinAfterPromiseResolvedSuccess={false}
                    onClick={restoreArchive}
                    leftIcon={<Img src={RestoreIcon} />}
                    padding='15px 25px'
                  >
                    Restore Archive
                  </AsyncButton>
                </Default>
              </Switch>
            </Flex>
          </Flex>

          <When condition={!!transfer?.Project}>
            <ProjectOverview projectId={transfer?.Project.ProjectNumber!} />
          </When>
          <ArchiveOverview archiveId={archiveId} />
          <Tabs isLazy lazyBehavior='unmount' index={tabIndex} onChange={setTabIndex}>
            <TabList width='100%' height='40px'>
              <TabWithIcon
                icon={FileIcon()}
                label='Files'
              />
              <TabWithIcon
                icon={ActivityIcon()}
                label='Activities'
              />
            </TabList>
            <TabPanels>
              <TabPanel>
                <FileViewTabs transfer={transfer} />
              </TabPanel>
              <TabPanel>
                <ActivitiesTable transferId={archiveId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </FileListContext.Provider>
    </AsyncWrapper>
  );
}
