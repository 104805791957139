/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
import {
  Box, Button, Checkbox, Flex, Image, ModalContentProps, Text,
} from '@chakra-ui/react';

import DownloadIcon from 'assets/icons/download.svg';
import SecurityNoticeIcon from 'assets/icons/info-icon.svg';
import EditFilesIcon from 'assets/security/securityimg.png';
import CantOpenFilesIcon from 'assets/security/securityimg2.png';
import AsyncButton from 'components/form/AsyncButton';
import { ModalFrame } from 'components/modal-frame/ModalFrame';

const content: ModalContentProps = {
  maxW: '800px',
  minW: '720px',
  marginTop: 50,
};

export interface IChromeSecurityNoticeProps {
  isOpen: boolean;
  onClose: () => void;
  onNext: () => Promise<void>;
}

export function ChromeSecurityNoticeModal({
  isOpen,
  onClose,
  onNext,
}: {
  isOpen: boolean,
  onClose: () => void,
  onNext: () => void,
}): JSX.Element {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (checked) {
      localStorage.setItem('disabled_chrome_security_notice', 'true');
    } else {
      localStorage.removeItem('disabled_chrome_security_notice');
    }
  };

  const Header = (
    <Box backgroundColor='gold.1000' fontSize='35px' borderRadius='10px' padding='15px' width='100%' paddingLeft='30px' marginBottom='25px'>
      <Flex direction='row' gap='10px'>
        <Image src={SecurityNoticeIcon} />
        <Text>Instructions: Please Review</Text>
      </Flex>
    </Box>
  );

  return (
    <ModalFrame
      hasCloseButton
      style={{ content }}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      header={Header}
    >
      <Box className='container'>
        <Flex gap='10' direction='column'>

          <Box bg='gray.900' borderRadius='15' padding='35' className='cont-1'>
            <Flex gap='10' direction='row' alignItems='center'>
              <Flex direction='column'>
                <Text fontWeight='bold' fontSize='25px'>1. Create a folder</Text>
                <Text fontWeight='regular' marginBottom='5' fontSize='14'>For security, Chrome won&apos;t allow downloading to system folders such as Desktop, Documents, Downloads, Music and Pictures.
                </Text>
              </Flex>
              <img width='130%' src={EditFilesIcon} />
            </Flex>
          </Box>

          <Box bg='gray.900' borderRadius='15' padding='35' className='cont-2'>
            <Flex gap='10' direction='row' alignItems='center'>
              <Flex direction='column'>
                <Text fontWeight='bold' fontSize='25px'>2. Allow &quot;Edit Files&quot;</Text>
                <Text fontWeight='regular' marginBottom='5' fontSize='14'>Note: your files aren&apos;t being edited - this is just an irritating security message from Chrome. It&apos;s safe to say &quot;Edit files&quot;.
                </Text>
              </Flex>
              <img width='130%' src={CantOpenFilesIcon} />
            </Flex>
          </Box>
        </Flex>
        <Flex marginTop='10' alignItems='center' gap='5'>
          <Button height='55px' onClick={() => onNext()}>
            <Flex gap='3'>
              <Image src={DownloadIcon} />
              <Text>Continue to download</Text>
            </Flex>
          </Button>
          <Text>
            <Checkbox type='checkbox' onChange={handleCheckboxChange}>
              Don&apos;t show this message again
            </Checkbox>
          </Text>
        </Flex>
      </Box>
    </ModalFrame>
  );
}
