import {
  ReactNode, createContext, useEffect, useMemo, useState,
} from 'react';

import SterlingSoundLogo from 'assets/sterling-sound-light-logo.svg';
import VillageLogo from 'assets/village-logo-white.svg';

type TenantIds = 'village' | 'sterling-sound';

export interface ITenantState {
  tenant: TenantIds;
  logoSrc: string;
}
const isValidTenant = (value: any): value is TenantIds => value === 'village' || value === 'sterling-sound';

export const TenantContext = createContext<ITenantState>({
  tenant: 'sterling-sound',
  logoSrc: SterlingSoundLogo,
});

export function TenantContextProvider({ children }: { children: ReactNode }): JSX.Element {
  const [tenant, setTenant] = useState<TenantIds>('sterling-sound');

  useEffect(() => {
    const localStorageTenant = localStorage.getItem('tenant') || '';

    if (isValidTenant(localStorageTenant)) {
      setTenant(localStorageTenant);
      return;
    }
    if (window.location.hostname === 'my.villagestudios.com' || window.location.hostname.endsWith('village.vercel.app')) {
      setTenant('village');
    } else {
      setTenant('sterling-sound');
    }
  }, []);

  const logoSrc = tenant === 'village' ? VillageLogo : SterlingSoundLogo;

  const state = useMemo(() => ({
    tenant,
    logoSrc,
  }), [tenant, logoSrc]);

  return (
    <TenantContext.Provider value={state}>
      {children}
    </TenantContext.Provider>
  );
}
