import {
  Flex, FormControl, FormErrorMessage, FormLabel, Heading, Image, Input, Text, useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';

import RoundedKeyIcon from 'assets/icons/rounded-key.svg';
import WarningIcon from 'assets/icons/warning.svg';
import AsyncButton from 'components/form/AsyncButton';

interface IResetPassword {
  email: string;
  password: string;
  passwordConfirm: string;
}

export default function AdminResetPasswordPage(): JSX.Element {
  const formMethods = useForm<IResetPassword>();
  const {
    register, handleSubmit, formState, setError,
  } = formMethods;
  const { errors } = formState;
  const toast = useToast();

  async function onSubmit(formInput: IResetPassword): Promise<void> {
    if (formInput.password !== formInput.passwordConfirm) {
      setError('passwordConfirm', { type: 'manual', message: 'Passwords do not match' });
      return;
    }

    await axios.post('/file-api/admin/change-password', { email: formInput.email, password: formInput.password });

    toast({
      title: 'Success',
      description: `Password changed for '${formInput.email}'`,
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }

  return (
    <Flex width={['320px', '400px']} direction='column' mx='auto' align='center'>
      <Image src={RoundedKeyIcon} h='70px' />
      <Flex direction='column' align='center' mt='35px' mb='50px' gap='25px'>
        <Heading fontSize='24px' fontWeight='600px'>Change User Password</Heading>
        <FormControl isInvalid={!!errors.email?.message}>
          <FormProvider {...formMethods}>
            <form autoComplete='false' autoCorrect='false' autoSave='false'>
              <FormLabel>Email</FormLabel>
              <Input
                autoFocus
                type='email'
                {...register('email', { required: 'Email address cannot be empty' })}
              />
              <FormLabel>Password</FormLabel>
              <Input
                autoFocus
                type='text'
                {...register('password', { required: 'Password is required' })}
              />
              <FormLabel>Confirm Password</FormLabel>
              <Input
                autoFocus
                type='text'
                {...register('passwordConfirm', { required: 'Confirm password is required' })}
              />
              <FormErrorMessage mt='10px' gap='10px' alignItems='baseline'>
                <Image src={WarningIcon} boxSize='15px' />
                <Text>{errors.email?.message} </Text>
              </FormErrorMessage>
              <AsyncButton
                formState={formState}
                spinAfterPromiseResolvedSuccess={false}
                type='submit'
                mt='15px'
                variant='login'
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </AsyncButton>
            </form>
          </FormProvider>
        </FormControl>
      </Flex>
    </Flex>
  );
}
