import {
  Flex, Image, Img, Progress, Text,
} from '@chakra-ui/react';
import pathParse from 'path-parse';
import {
  useEffect, useRef,
} from 'react';
import {
  Case, Default, Switch, When,
} from 'react-if';

import ArtistIcon from 'assets/icons/artist.svg';
import CompleteIcon from 'assets/icons/check-circle-green.svg';
import WarningIcon from 'assets/icons/circle-xmark-red.svg';
import WaitingIcon from 'assets/icons/clock-gray.svg';
import FolderOutlineIcon from 'assets/icons/folder-outline.svg';
import FolderIcon from 'assets/icons/folder.svg';
import ProjectIcon from 'assets/icons/project.svg';
import UploadingIcon from 'assets/icons/rotate-right-blue.svg';
import { TransferStatus } from 'hooks/transfer/TransferProgress';
import { ICreateTransfer, useAzureUpload } from 'hooks/useAzureUpload';
import { getRootDirectoryName } from 'utils/file';

export type ProjectMetadata = {
  name?: string,
  artist?: string,
};

export type ITransferTask = Omit<ICreateTransfer, 'description'> & {
  id: string,
  projectMetadata?: ProjectMetadata,
  onUploadComplete: (queue: string) => void,
  isMyTurn: boolean
};

function formatDirName(dirName: string): string {
  if (dirName.length === 1) return dirName; // dirname can be '/'
  return dirName.replace(/^\//, '');
}

function getIcon(status: TransferStatus): string {
  switch (status) {
    case TransferStatus.completed:
      return CompleteIcon;
    case TransferStatus.failed:
      return WarningIcon;
    case TransferStatus.inProgress:
      return UploadingIcon;
    default:
      return WaitingIcon;
  }
}

function getStyling(status: TransferStatus): { backgroundColor: string; borderColor: string; colorScheme: string; } {
  if (status === TransferStatus.completed) {
    return {
      backgroundColor: 'rgba(72, 187, 120, 0.06)',
      borderColor: 'green.400',
      colorScheme: 'green',
    };
  }
  if (status === TransferStatus.failed) {
    return {
      backgroundColor: 'rgba(197, 48, 48, 0.06)',
      borderColor: 'gold.1000',
      colorScheme: 'red',
    };
  }
  return {
    backgroundColor: 'rgba(160, 174, 192, 0.02)',
    borderColor: 'gray.400',
    colorScheme: 'blue',
  };
}

export function TransferTask({
  files,
  transferRequestId,
  projectId,
  projectMetadata,
  selectedRecipients,
  archive,
  requireMFA,
  workOrderIdentifier,
  enableSamply,
  id,
  isMyTurn,
  onUploadComplete,
}: ITransferTask): JSX.Element {
  const { createTransfer, progress } = useAzureUpload();

  const isTaskFailed = progress.state === TransferStatus.failed;
  const isTaskComplete = progress.state === TransferStatus.completed;

  const uploadName = useRef<string>('');
  const didInit = useRef<boolean>(false);

  useEffect(() => {
    const firstFilePath = files[0].path;
    const firstFileDir = pathParse(firstFilePath);
    const parentDirName = getRootDirectoryName(firstFileDir);
    uploadName.current = parentDirName;
    if (!isMyTurn || isTaskFailed || didInit.current) return;
    didInit.current = true;
    void createTransfer({
      files,
      projectId,
      transferRequestId,
      selectedRecipients,
      description: uploadName.current,
      archive,
      requireMFA,
      workOrderIdentifier,
      enableSamply,
    });
  }, [isMyTurn, isTaskFailed, didInit, createTransfer, projectId, transferRequestId, selectedRecipients, archive, requireMFA, enableSamply, files, workOrderIdentifier]);

  useEffect(() => {
    if (isMyTurn && (isTaskComplete || isTaskFailed)) {
      onUploadComplete(id);
    }
  }, [id, isMyTurn, onUploadComplete, isTaskComplete, isTaskFailed]);

  const styling = getStyling(progress.state);

  return (
    <Flex
      height='85px'
      p='25px 30px'
      align='center'
      gap='25px'
      border='2px'
      borderRadius='8px'
      borderColor={styling.borderColor}
      backgroundColor={styling.backgroundColor}
    >
      <Image height='32px' width='40px' src={FolderIcon} />
      <Flex flex='1 0 auto' flexDirection='column' gap='13px'>
        <Flex fontWeight='500' justifyContent='space-between' align='center'>
          <Flex gap='15px' alignItems='center'>
            {' '}
            {/* will contain folder & project infos */}
            <Flex gap='5px'>
              {' '}
              {/* groups icon & text */}
              <Img h='15px' w='20px' src={FolderOutlineIcon} />
              <Text fontSize='12px' flexShrink='0'>{formatDirName(uploadName.current)}</Text>
            </Flex>
            <When condition={!!projectMetadata}>
              <Text>|</Text>
              <Flex gap='5px'>
                <Img h='15px' w='15px' src={ProjectIcon} />
                <Text fontSize='12px' flexShrink='0'>{projectMetadata?.name}</Text>
              </Flex>
              <Text>|</Text>
              <Flex gap='5px'>
                <Img h='15px' w='10px' src={ArtistIcon} />
                <Text fontSize='12px' flexShrink='0'>{projectMetadata?.artist}</Text>
              </Flex>
            </When>
          </Flex>
          <Switch>
            <Case condition={!isMyTurn && progress.isActive}>Waiting...</Case>
            <Case condition={progress.state === TransferStatus.pending}>Initializing...</Case>
            <Case condition={progress.state === TransferStatus.completed}>Upload Completed -- {progress.progressStatus}</Case>
            <Case condition={progress.state === TransferStatus.inProgress}>{progress.progressStatus}</Case>
            <Case condition={progress.state === TransferStatus.retrying}>Retying -- {progress.progressStatus}</Case>
            <Case condition={progress.state === TransferStatus.failed}>Failed -- {progress.progressStatus}</Case>
            <Default>`Unknown condition ${progress.state}`</Default>
          </Switch>
        </Flex>
        <Progress
          borderRadius='20px'
          height='5px'
          colorScheme={styling.colorScheme}
          value={progress.percent}
          isIndeterminate={(progress.state === TransferStatus.pending)}
        />
      </Flex>
      <Flex w='60px' align='center' gap='9px'>
        <Image
          boxSize='25px'
          src={getIcon(progress.state)}
        />
        <Text fontSize='14px'>
          {progress.percentText}
        </Text>
      </Flex>
    </Flex>
  );
}
