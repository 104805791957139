import { ArrayBuffer as SparkArrayBuffer } from 'spark-md5';

import { EMPTY_FILE_HASH } from 'pages/transfers/files-page/components/helpers/version';
import { IStudioErrorOptions, StudioError } from 'StudioError';

export class AudioHashError extends StudioError {
  public constructor(
    public code: string,
    message: string,
    public options?: IStudioErrorOptions,
  ) {
    super(code, message, options);
  }
}

function Uint8ArrayToASCII(fileData: Uint8Array): string {
  let dataString = '';
  for (let i = 0; i < fileData.length; i += 1) {
    dataString += String.fromCharCode(fileData[i]);
  }
  return dataString;
}

function parseChunkHeader(fileBuffer: ArrayBuffer, fileOffset: number): [number, string, number] {
  let offset = fileOffset;
  let ckId = Uint8ArrayToASCII(new Uint8Array(fileBuffer.slice(offset, offset + 4)));
  while (ckId !== 'data' && ckId !== 'fmt ') {
    if (offset + 1 > fileBuffer.byteLength) {
      throw new AudioHashError('File is missing data or fmt chunk', `Offset: ${offset}`);
    }
    if (offset > 1024) {
      throw new AudioHashError('Couldn\'t find data chunk within 1024 bytes', `Offset: ${offset}`);
    }

    offset += 1;
    ckId = Uint8ArrayToASCII(new Uint8Array(fileBuffer.slice(offset, offset + 4)));
  }
  offset += 4;
  const ckSize = new DataView(fileBuffer).getUint32(offset, true);
  offset += 4;
  return [offset - fileOffset, ckId, ckSize];
}

function preProcessFileBuffer(fileBuffer: ArrayBuffer): number {
  const fileSize = fileBuffer.byteLength;

  const metadataSize = 12; // 4 bytes for ckID, 4 bytes for ckSize, 4 bytes for WAVEID
  if (fileSize < metadataSize) {
    throw new AudioHashError('File is too small', `Expected at least ${metadataSize} bytes, got ${fileSize} bytes`);
  }

  let offset = 0;

  // first 4 bytes should be 'RIFF' in ascii
  const ckID = Uint8ArrayToASCII(new Uint8Array(fileBuffer.slice(offset, offset + 4)));
  if (ckID !== 'RIFF') {
    throw new AudioHashError('File chunk ID is incorrect', `Expected 'RIFF', got '${ckID}'`);
  }
  offset += 4;

  // next 4 bytes are chunk size
  const ckSize = new DataView(fileBuffer).getUint32(offset, true);
  if (ckSize !== fileSize - 8) {
    throw new AudioHashError('File chunk size is incorrect', ` Expected ${fileSize - 8}, got ${ckSize}`);
  }
  offset += 4;

  // next 4 bytes are WAVEID, should be 'WAVE' in ascii
  const WAVEID = Uint8ArrayToASCII(new Uint8Array(fileBuffer.slice(offset, offset + 4)));
  if (WAVEID !== 'WAVE') {
    throw new AudioHashError('File WAVE ID is incorrect', `Expected 'WAVE', got '${WAVEID}'`);
  }
  offset += 4;

  return offset;
}

export async function calculateAudioMd5(file: File): Promise<string | undefined> {
  try {
    const fileBuffer = await file.arrayBuffer();
    let offset = preProcessFileBuffer(fileBuffer);

    const hash = new SparkArrayBuffer();
    while (offset < fileBuffer.byteLength) {
      const [chunkOffset, ckId, ckSize] = parseChunkHeader(fileBuffer, offset);
      offset += chunkOffset;
      if (ckId === 'fmt ') {
        offset += 16;
        continue;
      }
      if (ckId === 'data') {
        let localOffset = 0;
        while (localOffset < ckSize) {
          const bytesToRead = Math.min(ckSize - localOffset, 64 * 1024);
          const chunk = new Uint8Array(fileBuffer.slice(offset, offset + bytesToRead));
          hash.append(chunk);
          offset += bytesToRead;
          localOffset += bytesToRead;
        }
        break;
      }
      // non-data chunk, skip it
      offset += ckSize;
    }
    const audioHash = hash.end();
    if (audioHash === EMPTY_FILE_HASH) {
      return undefined;
    }
    return audioHash;
  } catch (e: any) {
    console.warn('Failed to calculate audio hash', e);
  }
}
