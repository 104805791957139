import {
  Avatar, Flex, Img, Tag, Tooltip,
} from '@chakra-ui/react';
import { type Row, createColumnHelper } from '@tanstack/react-table';
import {
  When,
} from 'react-if';
import { useNavigate } from 'react-router-dom';

import { transfersServerApiTypedMethods } from 'api/transfersServerApi';
import DownloadIcon from 'assets/icons/download.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import ButtonWithTooltip from 'components/ButtonWithTooltip';
import ChromeDownloadModal from 'components/ChromeDownloadModal';
import DateComponent from 'components/DateComponent';
import { ChromeSecurityNoticeModal } from 'components/google-context-modal/ChromeSecurityNoticeModal';
import ProjectName from 'components/ProjectName';
import SmsVerifyModal from 'components/SmsVerifyModal';
import {
  ArtistHeader,
  DateHeader, ExpiryHeader, FolderHeader, FromHeader, ProjectHeader, SizeHeader, TeamHeader,
} from 'components/table/headers';
import StudiumTable from 'components/table/StudiumTable';
import { useAzureDownload } from 'hooks/useAzureDownload';
import useUserContext from 'hooks/useUserContext';
import {
  Files,
  TransferCTRow,
} from 'types/api';
import { isExpired } from 'utils/file';
import { filesSizeTags } from 'utils/formatter';

export type TransfersTableColumns = {
  createdBy?: boolean,
  artist?: boolean,
  project?: boolean,
  name?: boolean,
  date?: boolean,
  expiry?: boolean,
  size?: boolean,
  resource?: boolean,
};

export type TransfersTableProps = {
  path: string,
  invisibleColumns?: TransfersTableColumns,
  emptyTableState: {
    message: string,
    icon: string,
  },
};

export default function TransfersTable({
  path,
  invisibleColumns,
  emptyTableState,
}: TransfersTableProps): JSX.Element {
  const navigate = useNavigate();
  const {
    downloadBlobs, smsModalProps, chromeDownloadModalProps, chromeSecurityNotificationProps,
  } = useAzureDownload();
  const { isStudioMember } = useUserContext();

  const columnHelper = createColumnHelper<TransferCTRow>();

  const transferColumns = [
    columnHelper.display({
      id: 'from',
      header: FromHeader,
      cell: ({ row }) => (() => {
        if (isStudioMember) {
          return row.original.createdBy;
        }
        return row.original.resource;
      }
      )(),
    }),
    columnHelper.accessor('artist', {
      cell: (info) => info.getValue(),
      header: ArtistHeader,
    }),
    columnHelper.accessor('project', {
      cell: (info) => ProjectName(info.getValue()),
      header: ProjectHeader,
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue() || '-',
      header: FolderHeader,
    }),
    columnHelper.accessor('date', {
      cell: (info) => (() => (
        <DateComponent date={info.getValue()} />
      ))(),
      header: DateHeader,
    }),
    columnHelper.display({
      id: 'size',
      header: SizeHeader,
      cell: ({ row }) => filesSizeTags(row.original.size, row.original.fileCount),
    }),
    columnHelper.display({
      id: 'expiry',
      header: ExpiryHeader,
      cell: ({ row }) => {
        const { canceled } = row.original;
        if (canceled) {
          return (() => <Tag colorScheme='red'>Deleted</Tag>)();
        }

        const { expired, expiresAt } = isExpired({
          DateCreated: row.original.date,
          BlobDeleted: row.original.blobDeleted,
        });

        if (expired) {
          return (() => <Tag colorScheme='red'>Expired</Tag>)();
        }
        return (() => <DateComponent date={expiresAt} />)();
      },
    }),
    columnHelper.display({
      id: 'resource',
      header: TeamHeader,
      cell: ({ row }) => (() => (
        <Tooltip label={row.original.resource}>
          <Avatar size='sm' name={row.original.resource || 'unknown'} />
        </Tooltip>
      ))(),
    }),
    columnHelper.display({
      id: 'Actions',
      header: '',
      cell: ({ row }) => (() => {
        const { expired } = isExpired({
          DateCreated: row.original.date,
          BlobDeleted: row.original.blobDeleted,
        });
        const isMfa = row.original.smsVerificationRequired;
        return (
          <When condition={!expired}>
            <Flex alignItems='center'>
              <ButtonWithTooltip
                onClick={() => downloadTransfer(row.original)}
                title='Download'
                icon={<Img w='20px' h='15px' src={DownloadIcon} />}
              />
              <Tooltip
                label={isMfa ? 'SMS Verification Required' : ''}
                placement='top'
              >
                <Img w='20px' h='15px' opacity={isMfa ? '35%' : '0%'} src={PhoneIcon} />
              </Tooltip>
            </Flex>

          </When>
        );
      })(),
    }),
  ];

  function onRowClick(row: Row<TransferCTRow>): void {
    navigate(`/transfers/transfers/${row.original.id}`);
  }

  async function downloadTransfer(row: TransferCTRow): Promise<void> {
    const fileListRequest = await transfersServerApiTypedMethods.GET<Files[]>('/api-v2/transfers/{transferId}/file-list', {
      params: { path: { transferId: Number(row.id) } },
    });

    if (fileListRequest) {
      await downloadBlobs({
        blobs: fileListRequest,
        artist: row.artist,
      });
    }
  }

  return (
    <>
      <ChromeSecurityNoticeModal {...chromeSecurityNotificationProps} />
      <SmsVerifyModal {...smsModalProps} />
      <ChromeDownloadModal {...chromeDownloadModalProps} />
      <StudiumTable
        apiPath={path}
        columns={transferColumns}
        invisibleColumns={invisibleColumns}
        handleRowClick={onRowClick}
        stateKey='state'
        emptyTableState={emptyTableState}
        queryKeys={['transfer-table']}
      />
    </>
  );
}
