import { Outlet, Route } from 'react-router';

import ConfirmPurchaseOrderRequest from 'pages/confirm-purchase-order-request/ConfirmPurchaseOrderRequest';
import PurchaseOrderConfirmed from 'pages/confirm-purchase-order-request/PurchaseOrderConfirmed';
import InvoicePaid from 'pages/portal/InvoicePaid';
import PayInvoiceRedirect from 'pages/portal/PayInvoiceRedirect';

export default function usePortalRoutes(): JSX.Element {
  return (
    <Route
      path='/portal/*'
      element={(
        <Outlet />
      )}
    >
      <Route path='confirm-po-request/:purchaseOrderApprovalRequestIdentifier' element={<ConfirmPurchaseOrderRequest />} />
      <Route path='po-request-approved/:purchaseOrderApprovalRequestIdentifier' element={<PurchaseOrderConfirmed />} />
      <Route path='pay-invoice/:contactIdentifier/:projectInvoiceIdentifier/' element={<PayInvoiceRedirect />} />
      <Route path='pay-invoice/paid/:contactIdentifier/:projectInvoiceIdentifier/:checkoutSessionId?' element={<InvoicePaid />} />
    </Route>
  );
}
