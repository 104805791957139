import {
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Else, If, Then } from 'react-if';
import { useNavigate, useParams } from 'react-router';

import { studioServerApi } from 'api/studioServerApi';
import SterlingSoundLogo from 'assets/sterling-sound-light-logo.svg';
import StudiumSpinner from 'components/StudiumSpinner';
import { toGuidString } from 'utils/formatter';

export default function PayInvoiceRedirect(): JSX.Element {
  const navigate = useNavigate();
  const { contactIdentifier, projectInvoiceIdentifier } = useParams();
  const hasProcessedInvoice = useRef(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  if (!contactIdentifier || !projectInvoiceIdentifier) {
    throw new Error('Invalid URL. Missing required parameters.');
  }

  const contactIdentifierGuid = useMemo(() => toGuidString(contactIdentifier), [contactIdentifier]);
  const projectInvoiceIdentifierGuid = useMemo(() => toGuidString(projectInvoiceIdentifier), [projectInvoiceIdentifier]);

  const { mutate: createStripeSession } = useMutation({
    mutationFn: async () => {
      const response = await studioServerApi.POST('/api/pay-invoice', {
        body: {
          ContactIdentifier: contactIdentifierGuid,
          ProjectInvoiceIdentifier: projectInvoiceIdentifierGuid,
        },
      });
      return response;
    },
    onSuccess: async (response) => {
      const { data } = response;
      const stripe = await loadStripe(data!.StripePublishableKey);
      if (!stripe) {
        setErrorMessage('Could not connect to payment provider.');
        return;
      }

      const { error } = await stripe.redirectToCheckout({ sessionId: data!.StripeSessionId });
      if (error) setErrorMessage(error.message || 'Failed to redirect to checkout');
    },
    onError: (err) => {
      if (err.message.match(/paid/gi) && err.message.match(/paid/gi)!.length > 0) {
        navigate(`/portal/pay-invoice/paid/${contactIdentifierGuid}/${projectInvoiceIdentifierGuid}`);
      }

      setErrorMessage(`Failed to initiate payment. ${err.message}`);
    },
  });

  useEffect(() => {
    if (!hasProcessedInvoice.current) {
      hasProcessedInvoice.current = true;

      createStripeSession();
    }
  }, [createStripeSession]);

  return (
    <If condition={!!errorMessage}>
      <Then>
        <Flex direction='column' align='center' w='auto' mx='auto' mt='50px'>
          <Image h='65px' mb='50px' src={SterlingSoundLogo} alt='Sterling Sound Logo' />
          <Text>{errorMessage}</Text>
        </Flex>
      </Then>
      <Else>
        <StudiumSpinner />
      </Else>
    </If>
  );
}
