import {
  Box, Flex, Heading, Image, Spacer, Stack, Text,
} from '@chakra-ui/react';

import EmailIcon from 'assets/icons/email-icon.svg';
import EngineersIcon from 'assets/icons/engineers-icon.svg';
import WebIcon from 'assets/icons/web-icon.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import ChannelCheckbox from 'components/notifications/ChannelCheckbox';
import useAxios from 'hooks/useAxios';
import useUserContext from 'hooks/useUserContext';
import { Subscription } from 'types/api';

export default function EngSubscriptions(): JSX.Element {
  const { user } = useUserContext();
  const [subscriptions] = useAxios<Subscription[]>(`/file-api/subscriptions?accountId=${user!.AccountNumber}`);

  return (
    <AsyncWrapper requests={[subscriptions]}>
      <Box marginLeft='100' minW='720px'>
        <Stack marginBottom='50px'>
          <Heading fontSize='4xl' as='b'>Subscriptions Settings</Heading>
          <Text>You have the option to select one or both preferences for receiving notifications regarding transfers</Text>
        </Stack>

        <Box bg='gray.800' borderRadius='15' p='35px 45px' w='80%' minWidth='720px' marginBottom='70px'>

          <Flex direction='row' gap='20'>

            <Flex dir='row' gap='5' alignItems='center'>
              <Image src={WebIcon} w='57px' />
              <Flex direction='column'>
                <Heading fontSize='22px' color='gray.400'>Web Notifications</Heading>
                <Text>Transfers will appear in your Studio Manager inbox, sent, projects and requests</Text>
              </Flex>
            </Flex>

            <Flex dir='row' gap='5' alignItems='center'>
              <Image src={EmailIcon} w='57px' />
              <Flex direction='column'>
                <Heading fontSize='22px' color='gray.400'>Email Notifications</Heading>
                <Text>Transfers will appear in your personal email inbox</Text>
              </Flex>
            </Flex>

          </Flex>
        </Box>

        <Flex maxWidth='80%' minW='720px' justifySelf='center' marginBottom='15px'>

          <Flex dir='row' alignItems='center' gap='3'>
            <Image src={EngineersIcon} w='23px' />
            <Heading fontSize='22px' color='gray.400'>Engineers</Heading>
          </Flex>
          <Spacer />
          <Flex gap='234px' marginRight='72px'>
            <Flex dir='row' alignItems='center' gap='3'>
              <Image src={WebIcon} w='23px' />
              <Heading fontSize='22px' color='gray.400'>Web</Heading>
            </Flex>

            <Flex dir='row' alignItems='center' gap='3'>
              <Image src={EmailIcon} w='23px' />
              <Heading fontSize='22px' color='gray.400'>Email</Heading>
            </Flex>
          </Flex>

        </Flex>
        <Flex flexDir='column' gap='25px'>
          {subscriptions.data?.map((subscription) => (
            <ChannelCheckbox subscription={subscription} />
          ))}
        </Flex>
      </Box>
    </AsyncWrapper>
  );
}
