import humanFormat from 'human-format';
import SparkMD5 from 'spark-md5';

export function b64ToHex(b64: string | null): string | null {
  if (!b64) return null;
  const raw = atob(b64);
  let result = '';
  for (let i = 0; i < raw.length; i += 1) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : `0${hex}`;
  }
  return result.toLowerCase();
}

export async function calculateFileMd5(file: File): Promise<string> {
  console.time(`Calculating md5 ${file.name}`);
  return await new Promise((resolve, reject) => {
    const blobSlice = File.prototype.slice;

    const chunkSize = humanFormat.parse('64 MB');
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      spark.append(e.target!.result as ArrayBuffer); // Append array buffer
      currentChunk += 1;

      if (currentChunk <= chunks) {
        loadNext();
      } else {
        const resolvedSpark = spark.end();
        console.timeEnd(`Calculating md5 ${file.name}`);
        resolve(resolvedSpark);
      }
    };

    fileReader.onerror = () => {
      reject(new Error('Error calculating MD5 for file'));
    };

    function loadNext(): void {
      const start = currentChunk * chunkSize;
      const end = (start + chunkSize) >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();
  });
}
