import createClient, { Middleware } from 'openapi-fetch';

import { authMiddleware, errorMiddleware } from './serverApiMiddleware';
import { paths } from 'studio-api-clients/studio-server';

const loggerMiddleware: Middleware = {
  onRequest({ request, options }) {
    console.log('onRequest', request, options);
    return request;
  },
};

export const studioServerApi = createClient<paths>({ baseUrl: '/' });

studioServerApi.use(
  // loggerMiddleware,
  authMiddleware,
  errorMiddleware,
);
