import { Flex } from '@chakra-ui/react';
import { Dispatch, useState } from 'react';
import { v4 } from 'uuid';

import { Dropzone } from './Dropzone';
import { ProjectMetadata } from './TransferTask';
import { useTransfers } from 'contexts/transfer/TransferProvider';
import useAlert from 'hooks/useAlert';
import { IFileWithPath } from 'hooks/useAzureUpload';
import { TransferRequests } from 'types/api';

interface IUploadProps {
  height?: string;
  uploadRequest?: TransferRequests;
  projectMetadata?: ProjectMetadata;
  uploadToProject?: IUploadToProjectProps;
  onUploadBegin?: () => void;
}

interface IUploadToProjectProps {
  projectId: number;
  selectedRecipients: string[];
  requireMFA: boolean;
  enableSamply: boolean;
  isArchive: boolean;
  workOrderIdentifier?: string;
  setShowRecipientError?: Dispatch<React.SetStateAction<boolean>>
}

export function Upload({
  height,
  uploadRequest,
  projectMetadata,
  uploadToProject,
  onUploadBegin,
}: IUploadProps): JSX.Element {
  const { errorToast } = useAlert();

  const {
    pushTask, pushToQueue,
  } = useTransfers();

  function onFilesSelected(files: IFileWithPath[]): void {
    onUploadBegin?.();
    if (!!uploadToProject?.setShowRecipientError && !uploadToProject?.selectedRecipients.length) {
      uploadToProject.setShowRecipientError(true);
      return;
    }

    const totalSize = files.reduce((accumulator, currentFile) => accumulator + currentFile.size, 0);
    if (totalSize === 0) {
      errorToast('Your upload is empty. Please upload a file/folder with content');
      return;
    }

    const key = v4();
    pushToQueue(key);
    pushTask({
      id: key,
      files,
      projectMetadata,
      transferRequestId: uploadRequest?.TransferRequestId,
      projectId: uploadToProject?.projectId,
      selectedRecipients: uploadToProject?.selectedRecipients || [],
      archive: uploadToProject?.isArchive ?? false,
      requireMFA: uploadToProject?.requireMFA ?? false,
      enableSamply: uploadToProject?.enableSamply ?? false,
      workOrderIdentifier: uploadToProject?.workOrderIdentifier ?? undefined, // This is where it's passed
    });
  }

  return (
    <Dropzone
      height={height}
      onFilesSelected={onFilesSelected}
    />
  );
}
