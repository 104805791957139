import {
  TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import { useContext } from 'react';

import ChangePasswordPage from './ChangePasswordPage';
import { PasswordSettingsIcon } from './components/PasswordSettingsIcon';
import { SecurityIcon } from './components/SecurityIcon';
import { TwoFactorIcon } from './components/TwoFactorIcon';
import OauthUsersPage from './OauthUsersPage';
import TwoFactorPage from './TwoFactorPage';
import { StudiumTableTab } from 'components/StudiumTableTab';
import { UserContext } from 'contexts/user/UserContextProvider';

export default function SecuritySettingsPage(): JSX.Element {
  const { signInProvider } = useContext(UserContext);

  const tabs = [];
  const panels = [];

  if (signInProvider === 'microsoft.com' || signInProvider === 'google.com') {
    panels.push(
      <TabPanel key='oauth-panel'>
        <OauthUsersPage />
      </TabPanel>,
    );
  } else {
    tabs.push(
      <StudiumTableTab key='password' icon={<PasswordSettingsIcon />}>
        Password Settings
      </StudiumTableTab>,
    );

    panels.push(
      <TabPanel key='password-panel'>
        <ChangePasswordPage />
      </TabPanel>,
    );

    tabs.push(
      <StudiumTableTab key='twofactor' icon={<TwoFactorIcon />}>
        2-Step Verification
      </StudiumTableTab>,
    );

    panels.push(
      <TabPanel key='twofactor-panel'>
        <TwoFactorPage />
      </TabPanel>,
    );
  }

  return (
    <Tabs variant='unstyled' isLazy lazyBehavior='unmount'>
      <TabList mb='64px'>{tabs}</TabList>
      <TabPanels>{panels}</TabPanels>
    </Tabs>
  );
}
