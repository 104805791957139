import createClient, { FetchResponse } from 'openapi-fetch';

import { authMiddleware, errorMiddleware } from './serverApiMiddleware';
import { paths } from 'studio-api-clients/transfers-server';

export type HttpMethod =
  | 'get'
  | 'put'
  | 'post'
  | 'delete'
  | 'options'
  | 'head'
  | 'patch'
  | 'trace';

export type PathsWithMethod<
  Paths extends {},
  PathnameMethod extends HttpMethod,
> = {
  [Pathname in keyof Paths]: Paths[Pathname] extends {
    [K in PathnameMethod]: any;
  }
    ? Pathname
    : never;
}[keyof Paths];

export const transferServerApi = createClient<paths>({
  baseUrl: '/',
});

transferServerApi.use(
  authMiddleware,
  errorMiddleware,
);

export async function typedResponseGet<ResponseType>(
  path: PathsWithMethod<paths, 'get'>,
  options: any,
): Promise<ResponseType> {
  const response = await transferServerApi.GET(path, options);

  return response.data as ResponseType;
}

export async function typedResponsePost<ResponseType>(
  path: PathsWithMethod<paths, 'post'>,
  options: any,
): Promise<ResponseType | undefined> {
  const response = await transferServerApi.POST(path, options) as any;

  return response.data as ResponseType;
}

export async function typedResponsePut<ResponseType>(
  path: PathsWithMethod<paths, 'put'>,
  options: any,
): Promise<ResponseType> {
  const response = await transferServerApi.PUT(path, options) as any;

  return response.data as ResponseType;
}

export async function typedResponseDelete<ResponseType>(
  path: PathsWithMethod<paths, 'delete'>,
  options: any,
) {
  const response = await transferServerApi.DELETE(path, options) as any;

  return response.data as ResponseType;
}

export const transfersServerApiTypedMethods = {
  GET: typedResponseGet,
  POST: typedResponsePost,
  PUT: typedResponsePut,
};
