import {
  Flex, Heading, Img, FormLabel as Label, LightMode, Switch, Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { When } from 'react-if';
import { useParams } from 'react-router';
import { useGate } from 'statsig-react';

import { TransferTaskList } from './components/TransferTaskList';
import WorkOrderDropdown from './components/WorkOrderDropdown';
import { ProjectOverview } from '../project-overview-page/components/ProjectOverview';
import PhoneIcon from 'assets/icons/phone.svg';
import SamplyIcon from 'assets/icons/samply.svg';
import WarningIcon from 'assets/icons/warning.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AuthorizeDisplay from 'components/AuthorizeDisplay';
import RecipientCheckBox from 'components/RecipientCheckBox';
import { Roles } from 'contexts/user/claims';
import useAxios from 'hooks/useAxios';
import useUserContext from 'hooks/useUserContext';
import { Upload } from 'pages/transfers/upload-transfer-pages/components/Upload';
import { StatsigGates } from 'StatsigGates';
import { Contacts } from 'types/api';

function LabelWithSwitch({
  icon, label, isChecked, onChange,
}: { icon: string, label: string, isChecked: boolean, onChange: () => void }): JSX.Element {
  return (
    <Flex width='100%' justifyContent='space-between'>
      <Flex align='center' gap='7px'>
        <Img color='white' height='20px' width='20px' src={icon} />
        <Label fontWeight='700' size='14px' lineHeight='20px' m='0'>{label}</Label>
      </Flex>
      <Flex h='20px' align='center'>
        <LightMode>
          <Switch colorScheme='red' size='sm' isChecked={isChecked} onChange={onChange} />
        </LightMode>
      </Flex>
    </Flex>
  );
}

export function UploadTransferPage(): JSX.Element {
  const { projectId } = useParams();
  const { isStudioMember } = useUserContext();
  const [contacts] = useAxios<Contacts[]>(`/file-api/contacts/${projectId}`);

  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const [requireMFA, setRequireMFA] = useState(false);
  const [enableSamply, setEnableSamply] = useState(!!isStudioMember);
  const [showRecipientError, setShowRecipientError] = useState(false);
  const [workOrderIdentifier, setWorkOrderIdentifier] = useState<string | undefined>(undefined);
  const { value: linkTransferToWorkOrder } = useGate(StatsigGates.LINK_TRANSFER_TO_WORK_ORDER);

  useEffect(() => {
    if (selectedRecipients.length > 0 && isStudioMember) setShowRecipientError(false);
  }, [selectedRecipients, isStudioMember]);

  return (
    <AsyncWrapper requests={[contacts]}>
      <Flex direction='column' gap='50px'>
        <Heading fontWeight='700px' fontSize='20px' lineHeight='31px'>New Transfer Upload</Heading>
        <ProjectOverview projectId={projectId} />
        <Flex direction='column' gap='15px'>
          <AuthorizeDisplay role={Roles.StudioMember}>
            <Flex
              direction='row'
              border='1px solid'
              borderColor={showRecipientError ? 'gold.1000' : 'gray.400'}
              borderRadius='15px'
              p='25px'
              gap='100px'
            >
              <Flex direction='column' gap='15px' minW='400px'>
                <Label fontWeight='700' size='14px' lineHeight='16px' m='0'>Settings</Label>
                <LabelWithSwitch
                  icon={PhoneIcon}
                  label='SMS Verification'
                  isChecked={requireMFA}
                  onChange={() => setRequireMFA(!requireMFA)}
                />
                <When condition='samplyIntegrationEnabled'>
                  <LabelWithSwitch
                    icon={SamplyIcon}
                    label='Create Samply Link'
                    isChecked={enableSamply}
                    onChange={() => setEnableSamply(!enableSamply)}
                  />
                </When>
                <When condition={!!linkTransferToWorkOrder}>
                  <Flex direction='column' gap='15px' mt='30px'>
                    <Label fontWeight='700' size='14px' lineHeight='16px' m='0'>Work Order</Label>
                    <WorkOrderDropdown projectNumber={projectId!} onSelect={setWorkOrderIdentifier} />
                  </Flex>
                </When>
              </Flex>

              <Flex direction='column' gap='15px' flex='1 0 300px' maxW='500px'>
                <Label fontWeight='700' size='14px' lineHeight='16px' m='0'>Recipients</Label>
                <Flex flexFlow='column wrap' height='80px' columnGap='50px' rowGap='5px'>
                  {contacts.data?.map((recipient: Contacts) => (
                    <RecipientCheckBox
                      contact={recipient}
                      selectedRecipients={selectedRecipients}
                      setSelectedRecipients={setSelectedRecipients}
                      key={recipient.ContactIdentifier}
                    />
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </AuthorizeDisplay>
          <When condition={showRecipientError}>
            <Text textStyle='paragraphReg' color='gold.1000' ml='26px' display='flex' alignItems='baseline' gap='7px'>
              <Img height='15px' width='15px' src={WarningIcon} />
              In order to upload files, you must first select at least one recipient.
            </Text>
          </When>
        </Flex>

        <Upload
          uploadToProject={{
            projectId: Number(projectId),
            selectedRecipients,
            requireMFA,
            enableSamply,
            isArchive: false,
            workOrderIdentifier,
            setShowRecipientError: isStudioMember ? setShowRecipientError : undefined,
          }}
        />
        <TransferTaskList />
      </Flex>
    </AsyncWrapper>
  );
}
