import {
  Avatar, Box, Flex, Text, Tooltip,
} from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import { useMemo } from 'react';

import { IWorkOrderDetails } from 'api/interfaces';
import { studioServerApi } from 'api/studioServerApi';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import useAxios from 'hooks/useAxios';

interface WorkOrderDropdownProps {
  projectNumber: string;
  onSelect: (workOrderId: string) => void;
}

function WorkOrderDropdown({ projectNumber, onSelect }: WorkOrderDropdownProps): JSX.Element {
  const [{ data }] = useAxios<{ projectIdentifier: string }>(`/file-api/projects/${projectNumber}/identifier`);

  const workOrdersQuery = useApiQuery({
    queryKey: ['workOrders', data?.projectIdentifier],
    queryFn: () => studioServerApi.GET(
      '/api/projects/{projectIdentifier}/work-orders/display',
      {
        params: {
          path: { projectIdentifier: data?.projectIdentifier! },
        },
      },
    ),
    enabled: !!data?.projectIdentifier, // Only run query if projectIdentifier exists
  });

  const workOrderOptions = useMemo(() => {
    const orders = workOrdersQuery.apiResult?.data as IWorkOrderDetails[] || [];

    return orders.map((order) => ({
      value: order.WorkOrderIdentifier!,
      label: (
        <Flex alignItems='center' justifyContent='space-between' p={2}>
          <Box>
            <Text fontWeight='bold' color='gray.400'>
              Session:
              <Text as='span' color='white'>{` #${order.SessionNumber}`}</Text>
            </Text>
            <Text fontWeight='bold' color='gray.400'>
              Work Order:
              <Text as='span' color='white'>{` #${order.WorkOrderNumber}`}</Text>
            </Text>
            <Text fontWeight='bold' color='gray.400'>
              Part Name:
              <Text as='span' color='white'>{` #${order.PartNumber} ${order.PartName}`}</Text>
            </Text>
            {order.AssignedToContactFullName && (
              <Flex alignItems='center'>
                <Text fontWeight='bold' color='gray.400' mr={2}>Assigned to:</Text>
                <Tooltip label={order.AssignedToContactFullName}>
                  <Avatar size='sm' name={order.AssignedToContactFullName} />
                </Tooltip>
              </Flex>
            )}
          </Box>
        </Flex>
      ),
    }));
  }, [workOrdersQuery.apiResult]);

  return (
    <QuerySuspense queries={[workOrdersQuery.queryResult]}>
      <Box>
        <Select
          options={workOrderOptions}
          placeholder='Select a work order...'
          isSearchable={false}
          onChange={(selectedOption: SingleValue<{ value: string }>) => {
            if (selectedOption) {
              onSelect(selectedOption.value);
            }
          }}
          chakraStyles={{
            option: (provided) => ({
              ...provided,
              'border': '0.5px solid grey',
              'borderRadius': 'md',
              'marginBottom': '8px',
              ':hover': {
                backgroundColor: 'gray.600',
              },
            }),
          }}
        />
      </Box>
    </QuerySuspense>
  );
}

export default WorkOrderDropdown;
