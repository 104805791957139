import {
  Avatar, AvatarGroup, Flex, Img, Link, Text, Tooltip,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { When } from 'react-if';

import { SamplyOverview } from './SamplyOverview';
import DateIcon from 'assets/icons/date.svg';
import ExpiryIcon from 'assets/icons/expiry.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import SamplyIcon from 'assets/icons/samply.svg';
import SettingsIcon from 'assets/icons/settings.svg';
import SizeIcon from 'assets/icons/size.svg';
import TeamIcon from 'assets/icons/team.svg';
import TransferIcon from 'assets/icons/transfer.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AuthorizeDisplay from 'components/AuthorizeDisplay';
import DateComponent from 'components/DateComponent';
import { InfoSet } from 'components/Infoset';
import Overview, { OverviewDateFormat } from 'components/Overview';
import { Roles } from 'contexts/user/claims';
import useAxios from 'hooks/useAxios';
import { TransferOverviewResponse } from 'types/api';
import { isExpired } from 'utils/file';
import { filesSizeTags } from 'utils/formatter';

export function TransferOverview({ transferId }: { transferId?: string | number }): JSX.Element {
  const [overview] = useAxios<TransferOverviewResponse>(`/file-api/transfers/${transferId}/overview`, !transferId);
  const { expiresAt } = useMemo(() => {
    if (!overview.data) return { expiresAt: undefined };
    return isExpired({
      DateCreated: overview.data.date,
      BlobDeleted: overview.data.blobsDeleted,
    }, OverviewDateFormat);
  }, [overview]);

  return (
    <Overview
      header='Transfer Overview'
      background='purple.1000'
      before={{
        background: 'purple.600',
      }}
    >
      <AsyncWrapper
        requests={[overview]}
        spinnerProps={{
          color: 'white',
          m: 0,
          size: 'md',
          thickness: '2px',
        }}
        mountChildrenDiscrete
      >
        <InfoSet
          label='Transfer'
          icon={TransferIcon}
          value={(
            <Text as='span' whiteSpace='pre-wrap'>
              <Text as='span'>{overview.data?.id}: {overview.data?.name}</Text>
            </Text>
          )}
        />
        <AuthorizeDisplay role={Roles.StudioMember}>
          <InfoSet
            label='From'
            icon={TeamIcon}
            condition={!!overview.data?.from}
            value={(
              <Flex dir='row' gap='2'>
                <Tooltip label={`${overview.data?.from?.email} - ${overview.data?.from?.name ?? 'N/A'}`} hasArrow arrowSize={15}>
                  <Avatar size='sm' name={overview.data?.from?.name} borderColor='transparent' />
                </Tooltip>
              </Flex>
          )}
          />
        </AuthorizeDisplay>
        <InfoSet
          label='Recipients'
          icon={TeamIcon}
          condition={overview.data?.recipients?.length}
          value={(
            <Flex dir='row' gap='2'>
              <Tooltip label={overview.data?.recipients?.map((m) => m.name).join(', ')} hasArrow arrowSize={15}>
                <AvatarGroup size='sm' max={3}>
                  {overview.data?.recipients.map((recipient) => (
                    <Avatar name={recipient.name} borderColor='transparent' key={recipient.name} />
                  ))}
                </AvatarGroup>
              </Tooltip>
            </Flex>
          )}
        />
        <InfoSet
          label='Date'
          icon={DateIcon}
          value={overview.data?.date ? <DateComponent date={overview.data.date} /> : undefined}
        />
        <InfoSet
          label='Expiry'
          icon={ExpiryIcon}
          value={<DateComponent date={expiresAt} />}
        />
        <InfoSet
          label='Files'
          icon={SizeIcon}
          value={filesSizeTags(overview.data?.stats.fileSize, overview.data?.stats.fileCount)}
        />
        <When condition={overview.data?.requiresMfa}>
          <InfoSet
            label='Settings'
            icon={SettingsIcon}
            condition={overview.data?.samplyUrl || overview.data?.requiresMfa}
            value={(
              <Flex gap='15px'>
                <When condition={overview.data?.requiresMfa}>
                  <Flex alignItems='center' gap='7px'>
                    <Img h='15px' w='17px' src={PhoneIcon} />
                    <Text whiteSpace='nowrap'>SMS Required</Text>
                  </Flex>
                </When>
              </Flex>
          )}
          />
        </When>
        <SamplyOverview transferOverview={overview.data} />
      </AsyncWrapper>
    </Overview>
  );
}
