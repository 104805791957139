import {
  BlobServiceClient, BlockBlobTier, ContainerClient,
} from '@azure/storage-blob';
import humanFormat from 'human-format';
import { mapValues } from 'lodash';

import { createBuffer } from './bufferPipe';
import { calculateAudioMd5 } from './helpers/audioHash';
import { AudioMetadata, calculateAudioMetadata } from './helpers/audioMetadata';
import {
  b64ToHex, calculateFileMd5,
} from './helpers/fileHash';
import { DownloadError, UploadError } from '../../StudioError';
import { getDirHandle, sanitizeFileName } from '../../utils/file';
import { lookupMimeType } from 'hooks/helpers/mime';
import { Files, TransferAuthentications } from 'types/api';
import { NestedAbortController } from 'utils/NestedAbortController';

function getContainerClient(auth: TransferAuthentications): ContainerClient {
  const blobServiceClient = new BlobServiceClient(`${auth.storageAccountUrl}${auth.sasToken}`);
  console.time('Building container client...');
  const containerClient = blobServiceClient.getContainerClient(auth.containerName);
  console.timeEnd('Building container client...');

  return containerClient;
}

export type FileMetadata = {
  session_id: string;
  transfer_id: string;
  file_id: string;
  file_name: string;
  relative_path: string;
  size: string;
  file_hash: string | undefined;
  project_id: string;
};

export type BytesLoadedCallback = (loadedBytes: number) => void;

interface ITransferParams {
  auth: TransferAuthentications;
  transferFile: Files;
  onProgress: BytesLoadedCallback

  abortSignal: AbortSignal;
}

interface IDownloadBlobParams extends ITransferParams {
  rootDirHandle: FileSystemDirectoryHandle
}

interface IUploadBlobParams extends ITransferParams {
  tier: BlockBlobTier;
  file: File;
  reuseBlobs: boolean
}

function convertMD5ToBase16(md5: Uint8Array): string {
  function pad(str: string): string {
    return str.length < 2 ? `0${str}` : str;
  }

  return Array.from(md5, (v) => pad(v.toString(16))).join('');
}

async function getFileHandle(path: string, fileName: string, rootDir: FileSystemDirectoryHandle, writable: boolean): Promise<FileSystemFileHandle> {
  const sanitizedFilename = sanitizeFileName(fileName);
  const relativeDirHandle = await getDirHandle(path, rootDir);
  return await relativeDirHandle.getFileHandle(sanitizedFilename, { create: writable });
}

async function checkExistingFile(fileHandle: FileSystemFileHandle, file:Files): Promise<boolean> {
  const existingFile = await fileHandle.getFile();

  if (existingFile.size.toString() !== file.FileSize.toString()) {
    console.log(`File exists, but size doesn't match. "${file.RelativePath}/${file.FileName}"! Cloud: ${file.FileSize} Local: ${existingFile.size}.`);
    return false;
  }

  const md5 = await calculateFileMd5(existingFile);

  if (md5 !== file.FileHashMD5) {
    console.log(`File exists, but hash doesn't match."${file.RelativePath}/${file.FileName}"! Cloud: ${file.FileHashMD5} Local: ${md5}`);
    return false;
  }

  return true;
}

export async function downloadBlob(params: IDownloadBlobParams): Promise<void> {
  console.log('Downloading file ', params.transferFile.RelativePath, params.transferFile.FileName);
  const { transferFile } = params;

  const fetchTimeoutController = new NestedAbortController(params.abortSignal);

  const fetchTimeout = setTimeout(() => fetchTimeoutController.abort('Fetch operation has timedout'), 10000);
  const blobProps = await fetch(`${params.transferFile.BlobUrl}${params.auth.sasToken}`, {
    method: 'HEAD',
    signal: fetchTimeoutController.signal,
  });

  clearTimeout(fetchTimeout);

  if (!blobProps.ok) {
    throw new DownloadError(`Blob request failed: ${blobProps.status}`, blobProps.url);
  }

  const contentLength = blobProps.headers.get('content-length');
  const expectedMD5 = blobProps.headers.get('X-MS-META-FILE_HASH') || b64ToHex(blobProps.headers.get('content-md5')) || transferFile.FileHashMD5;
  if (contentLength !== transferFile.FileSize.toString()) {
    throw new DownloadError('Content length mismatch', `Expected: ${transferFile.FileSize} Actual: ${contentLength}`);
  }

  const fileHandle = await getFileHandle(transferFile.RelativePath, transferFile.FileName, params.rootDirHandle, true);

  const fileExists = await checkExistingFile(fileHandle, transferFile);

  if (fileExists) {
    params.onProgress(Number(transferFile.FileSize));
    console.log(`File exists. Skipping download. "${transferFile.FilePath}"`);
    return;
  }

  const blobResp = await fetch(`${params.transferFile.BlobUrl}${params.auth.sasToken}`, {
    signal: params.abortSignal,
  });

  if (!blobResp.ok) {
    throw new DownloadError(`Blob request failed: ${blobResp.status}`, blobResp.url);
  }

  let loadedBytes = 0;

  const progressTracker = new TransformStream<Uint8Array, Uint8Array>({
    async transform(chunk, controller) {
      if (!params.abortSignal.aborted) {
        loadedBytes += chunk.length;
        params.onProgress(loadedBytes);
        controller.enqueue(chunk);
      }
    },
  });

  const fileStream = await fileHandle.createWritable();

  console.time(`Downloaded... ${transferFile.FileName}`);

  const buffer = createBuffer();

  await blobResp.body!
    .pipeThrough(progressTracker)
    .pipeThrough(buffer)
    .pipeTo(fileStream);

  console.timeEnd(`Downloaded... ${transferFile.FileName}`);

  // verify download
  const writtenFile = await fileHandle.getFile();

  if (writtenFile.size.toString() !== contentLength) {
    throw new DownloadError('File size mismatch', `file "${transferFile.FileName}"! Cloud: ${contentLength} Local: ${writtenFile.size}`);
  }

  const md5 = await calculateFileMd5(writtenFile);
  const audioHash = await calculateAudioMd5(writtenFile);

  if (md5 !== expectedMD5) {
    // Temporary measure, some files have incorrect MD5s in azure and need to be checked against audio
    if (audioHash !== expectedMD5) {
      throw new DownloadError('Hash mismatch', `file "${transferFile.FileName}"! Cloud: ${expectedMD5} Local: ${md5}`);
    }
  }
  console.log(`MD5 verified: ${md5}`);

  // send and un-throttled progress event
  params.onProgress(loadedBytes);
}

export async function uploadBlob(params: IUploadBlobParams): Promise<Omit<AudioMetadata, 'wav_metadata_audio_hash_md5'> & FileMetadata> {
  const { transferFile } = params;
  const rawFileToUpload = params.file;
  console.time('Hashing file...');
  const fileHash = await calculateFileMd5(rawFileToUpload);
  const fileMeta = {
    session_id: transferFile.SessionId.toString(),
    transfer_id: transferFile.TransferId.toString(),
    file_id: `${transferFile.FileId}`,
    relative_path: transferFile.RelativePath,
    file_name: rawFileToUpload.name,
    size: rawFileToUpload.size.toString(),
    file_hash: fileHash,
    project_id: transferFile.ProjectIdentifier ?? '',
  };
  const audioMeta = await calculateAudioMetadata(rawFileToUpload) as AudioMetadata;
  if (audioMeta) {
    const audioHash = await calculateAudioMd5(rawFileToUpload);
    audioMeta.wav_metadata_audio_hash_md5 = audioHash;
  }

  console.timeEnd('Hashing file...');
  const containerClient = getContainerClient(params.auth);

  const blockBlobClient = containerClient.getBlockBlobClient(transferFile.UniqueIdentifier.toUpperCase());

  const mimeType = lookupMimeType(transferFile.FileName);

  const metadata = mapValues({
    ...fileMeta,
    ...audioMeta,
  }, (value) => encodeURIComponent(value || ''));

  let existingFile: Files | undefined;

  // https:// studium-33.sentry.io/issues/4745477005/?project=4504778001416192
  //   if (params.reuseBlobs) {
  //     try {
  //       const reuseResp = await axios.post<Files>(`/file-api/files/${transferFile.FileId}/reuse`, {
  //         metadata,
  //         tier: params.tier,
  //       }, { silentErrors: true });
  //
  //       existingFile = reuseResp.data;
  //     } catch (e) {
  //       console.warn('Failed to reuse file', e);
  //     }
  //   }

  if (existingFile) {
    params.onProgress(Number(existingFile.FileSize));
  } else {
    const resp = await blockBlobClient.uploadData(rawFileToUpload!, {
      // maxSingleShotSize: humanFormat.parse('4 MB'),
      blockSize: humanFormat.parse('1 MB'),
      onProgress: (ev) => {
        params.onProgress(ev.loadedBytes);
      },
      blobHTTPHeaders: {
        blobContentType: mimeType,
      },
      metadata,
      tier: params.tier,
      concurrency: 5,
      abortSignal: params.abortSignal,
    });

    console.timeEnd(`Upload Timer: ${transferFile.FileName}`);

    if (resp.contentMD5) {
      if (convertMD5ToBase16(resp.contentMD5) !== fileMeta.file_hash) {
        throw new UploadError('MD5 mismatch', `Expected: ${fileMeta.file_hash} Actual: ${resp.contentMD5}`);
      }
      console.log(`MD5 verified: ${fileMeta.file_hash}`);
    }
  }

  return {
    ...fileMeta,
    ...audioMeta,
  };
}
