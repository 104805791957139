import 'api/configureAxios';
import 'configureYup';

import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { When } from 'react-if';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import ErrorFallback from 'components/error/ErrorFallback';
import { GeoInfoProvider } from 'contexts/GeoInfoProvider';
import { TenantContextProvider } from 'contexts/tenant/TenantContextProvider';
import UserContextProvider from 'contexts/user/UserContextProvider';
import { env, isProduction } from 'env';
import { useUpdateCheck } from 'hooks/useUpdateCheck';
import RouteIndex from 'routes/RouteIndex';
import { newTheme } from 'themes/theme';

console.log('*************** App Started ***************');

const router = createBrowserRouter([
  { path: '*', element: <Root /> },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

function Root(): JSX.Element {
  useUpdateCheck();

  return (
    <ChakraProvider theme={newTheme} toastOptions={{ defaultOptions: { position: 'top', variant: 'solid' } }}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_CLIENT_ID}>
            <GeoInfoProvider>
              <TenantContextProvider>
                <UserContextProvider>
                  <RouteIndex />
                </UserContextProvider>
              </TenantContextProvider>
            </GeoInfoProvider>
          </GoogleOAuthProvider>
          <When condition={!isProduction}>
            <ReactQueryDevtools initialIsOpen={false} />
          </When>
        </QueryClientProvider>
      </ErrorBoundary>
    </ChakraProvider>
  );
}

function App(): JSX.Element {
  return <RouterProvider router={router} />;
}

export default App;
