import {
  Avatar, Box, Flex, Text, Tooltip,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { When } from 'react-if';

import { studioServerApi } from 'api/studioServerApi';
import PartName from 'assets/icons/PartName.svg'; // Replace with the correct icon
import Session from 'assets/icons/Session.svg'; // Replace with the correct icon
import TeamIcon from 'assets/icons/team.svg';
import WorkOrder from 'assets/icons/WorkOrder.svg'; // Replace with the correct icon
import { AsyncWrapper } from 'components/AsyncWrapper';
import { InfoSet } from 'components/Infoset';
import Overview from 'components/Overview';
import { useApiQuery } from 'hooks/useApiQuery';

export function WorkOrderOverview({ projectIdentifier, workOrderIdentifier }: { projectIdentifier: string; workOrderIdentifier: string }): JSX.Element {
  const workOrdersQuery = useApiQuery({
    queryKey: ['workOrders', projectIdentifier],
    queryFn: () => studioServerApi.GET(
      '/api/projects/{projectIdentifier}/work-orders/display',
      {
        params: { path: { projectIdentifier } },
      },
    ),
    enabled: !!projectIdentifier,
  });

  const workOrderInfo = useMemo(() => {
    const workOrders = workOrdersQuery?.apiResult?.data;

    return workOrders?.find((workOrder) => workOrder.WorkOrderIdentifier === workOrderIdentifier);
  }, [workOrdersQuery.apiResult, workOrderIdentifier]);

  return (
    <Overview
      header='Work Order Overview'
      background='blue.900'
      before={{ background: 'blue.600' }}
    >
      <AsyncWrapper
        requests={[{ loading: workOrdersQuery.queryResult.isLoading }]}
        spinnerProps={{
          color: 'white',
          m: 0,
          size: 'md',
          thickness: '2px',
        }}
        mountChildrenDiscrete
      >
        <Box borderRadius='10px'>
          <When condition={!!workOrderInfo}>
            <Flex direction='row' gap='100px' alignItems='center'>
              <InfoSet
                label='Session'
                icon={Session}
                value={<Text as='span'># {workOrderInfo?.SessionNumber}</Text>}
              />
              <InfoSet
                label='Work Order No.'
                icon={WorkOrder}
                value={<Text as='span'># {workOrderInfo?.WorkOrderNumber}</Text>}
              />
              <InfoSet
                label='Part Name'
                icon={PartName}
                value={<Text as='span'>{workOrderInfo?.PartNumber} {workOrderInfo?.PartName}</Text>}
              />
              {workOrderInfo?.AssignedToContactFullName && (
                <InfoSet
                  label='Assigned To'
                  icon={TeamIcon}
                  value={(
                    <Flex alignItems='center'>
                      <Tooltip label={workOrderInfo.AssignedToContactFullName}>
                        <Avatar size='sm' name={workOrderInfo.AssignedToContactFullName} />
                      </Tooltip>
                    </Flex>
                  )}
                />
              )}
            </Flex>
          </When>
        </Box>
      </AsyncWrapper>
    </Overview>
  );
}
