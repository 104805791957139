import { createStandaloneToast } from '@chakra-ui/react';
import axios from 'axios';
import { FirebaseError, initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider, OAuthCredential, OAuthProvider, User, browserLocalPersistence, getAuth, linkWithCredential, setPersistence, signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { orderBy, startCase } from 'lodash';

import { Sentry } from 'configureSentry';
import { env } from 'env';
import { OtpaInfoResponse } from 'types/api';
import { IUserProfileInfo } from 'types/studio-server';
import { logError } from 'utils/error';

const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
};

const { toast } = createStandaloneToast({
  defaultOptions: {
    status: 'error',
    position: 'top',
    isClosable: true,
  },
});

export enum PendingCredentialSessionStorageKeys {
  pendingCred = 'pendingCred',
  emailForSignIn = 'emailForSignIn',
}

initializeApp(firebaseConfig);

export function getPendingCredential(): OAuthCredential | null {
  const pendingCred = JSON.parse(sessionStorage.getItem(PendingCredentialSessionStorageKeys.pendingCred) as string);
  const cred = OAuthProvider.credentialFromError(pendingCred);

  return cred;
}

export async function linkAccounts({ user, oauthCredential }: { user: User, oauthCredential: OAuthCredential }): Promise<void> {
  await linkWithCredential(user, oauthCredential);

  toast({
    title: 'Success',
    description: 'Your accounts have been successfully linked, you can use your Microsoft account to login.',
    status: 'success',
    duration: 5000,
    position: 'top',
    isClosable: true,
  });

  sessionStorage.removeItem(PendingCredentialSessionStorageKeys.pendingCred);
  sessionStorage.removeItem(PendingCredentialSessionStorageKeys.emailForSignIn);
}

const googleAuthProvider = new GoogleAuthProvider();
const microsoftAuthProvider = new OAuthProvider('microsoft.com');

googleAuthProvider.setCustomParameters({
  prompt: 'select_account',
});

microsoftAuthProvider.setCustomParameters({
  prompt: 'select_account',
});

export const firebaseAuth = getAuth();

export async function signInWithGooglePopup(): Promise<void> {
  await signInWithPopup(firebaseAuth, googleAuthProvider);
}

export async function signInWithMicrosoftPopup(): Promise<void> {
  try {
    await signInWithPopup(firebaseAuth, microsoftAuthProvider);
  } catch (error: any) {
    if (error.code === 'auth/account-exists-with-different-credential') {
      sessionStorage.setItem(PendingCredentialSessionStorageKeys.pendingCred, JSON.stringify(error));
      sessionStorage.setItem(PendingCredentialSessionStorageKeys.emailForSignIn, error.customData.email);
      window.location.href = '/login/link-account';
    } else {
      logError(error);
      throw error;
    }
  }
}

firebaseAuth.onAuthStateChanged((user) => {
  console.log(`Firebase: ${user?.email}`, user);
  if (!user) {
    Sentry.setUser(null);
    Sentry.getCurrentScope()?.clear();
  } else {
    Sentry.setUser({
      ...user,
      username: user?.displayName || undefined,
      email: user?.email || undefined,
      id: user?.email || undefined,
    });
  }
});

export async function getAuthHeader(): Promise<string> {
  const token = await firebaseAuth.currentUser?.getIdToken(false);

  if (!token) {
    return '';
  }
  return `Bearer ${token.replaceAll('"', '')}`;
}

export async function logIn(email: string, password: string): Promise<OtpaInfoResponse & { user: User } | void> {
  await setPersistence(firebaseAuth, browserLocalPersistence);
  const result = await signInWithEmailAndPassword(firebaseAuth, email, password);
  try {
    const otpaInfo = await axios.get<OtpaInfoResponse>('/file-api/otpa');
    return { ...otpaInfo.data, user: result.user };
  } catch (err: any) {
    if (err.response?.data?.message) {
      toast({
        title: 'Error',
        description: err.response?.data?.message,
        status: 'error',
      });
    } else {
      throw err;
    }
  }
}

export function isFireBaseError(error: unknown): error is FirebaseError {
  return (error as FirebaseError).code !== undefined;
}

export function formatFireBaseError(error: FirebaseError): string {
  return `${startCase(error.code.replace('auth/', ''))} (${error.message.replace('Firebase: ', '').replace(/ \(auth\/.*\)\./, '')})`;
}

export async function signOut(params?: URLSearchParams | string, redirectUrl?: string): Promise<void> {
  console.warn('sign out flow triggered...');
  await axios.delete('/file-api/otpa');
  await firebaseAuth.signOut();
  localStorage.clear();
  sessionStorage.clear();
  const urlParams = params ? `?${new URLSearchParams(params).toString()}` : '';
  window.location.href = redirectUrl ? `/${redirectUrl + urlParams}` : `/login${urlParams}`;
}

export interface UserInfo {
  user: IUserProfileInfo,
  otpa?: OtpaInfoResponse | undefined,
}

export async function verifyOtpa(otpa: string): Promise<void> {
  await axios.post('/file-api/otpa/verify', {
    otpa,
  });
}

export async function fetchUserInfo(): Promise<IUserProfileInfo> {
  const userRequest = await axios.get<IUserProfileInfo[]>('/api/auth/me');
  return orderBy(userRequest.data, ['AccountNumber'])[0];
}
