import { CustomError } from 'ts-custom-error';

export interface IStudioErrorOptions {
  skipSentry?: boolean
}

export class StudioError extends CustomError {
  public constructor(
    public code: string,
    message?: string | undefined,
    public options?: IStudioErrorOptions,
  ) {
    super(message);
  }
}

export class AuthError extends StudioError {
  public constructor(
    public code: string,
    message?: string,
    public options?: IStudioErrorOptions,
  ) {
    super(code, message, options);
  }
}

abstract class TransferError extends StudioError {
  public constructor(
    public code: string,
    message?: string,
  ) {
    super(code, message, { skipSentry: false });
  }
}

export class DownloadError extends TransferError {
  public constructor(
    public code: string,
    message: string,
  ) {
    super(code, message);
  }
}

export class UploadError extends TransferError {
  public constructor(
    public code: string,
    message: string,
    public options?: IStudioErrorOptions,
  ) {
    super(code, message);
  }
}

export class StudioServerApiError extends StudioError {
  public constructor(
    public statusCode: string | number,
    uri: string,
    method: string,
    message: string,
    public options?: IStudioErrorOptions,
  ) {
    super(statusCode.toString(), `Status Code ${statusCode} from ${uri}:
     [${message}]`, options);
  }
}
