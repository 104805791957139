import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ApprovePOIcon from '../../assets/icons/approve-po.svg';
import ViewPOIcon from '../../assets/icons/view-po.svg';
import { studioServerApi } from 'api/studioServerApi';
import RequestsIcon from 'assets/icons/requests.svg';
import AsyncButton from 'components/form/AsyncButton';
import { IconInput } from 'components/form/IconInput';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import useTenantContext from 'hooks/useTenantContext';

type PurchaseOrderFormValues = {
  PurchaseOrderNumber: string;
};

export default function ConfirmPurchaseOrderRequest(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { purchaseOrderApprovalRequestIdentifier } = useParams();
  const { logoSrc } = useTenantContext();
  const navigate = useNavigate();

  const purchaseOrderApprovalRequestQuery = useApiQuery({
    queryKey: ['portal', 'purchase-order-request', purchaseOrderApprovalRequestIdentifier!],
    queryFn: () => studioServerApi.GET(
      '/api/portal/purchase-order-request/{purchaseOrderApprovalRequestIdentifier}',
      { params: { path: { purchaseOrderApprovalRequestIdentifier: purchaseOrderApprovalRequestIdentifier! } } },
    ),
  });

  const { mutateAsync } = useMutation({
    mutationFn: (purchaseOrderNumber: string) => studioServerApi.POST('/api/portal/purchase-order-request/approve', {
      body: {
        ContactIdentifier: purchaseOrderApprovalRequestQuery.apiResult?.data?.ContactIdentifier,
        ProjectPurchaseOrderRequestIdentifier: purchaseOrderApprovalRequestQuery.apiResult?.data?.ProjectPurchaseOrderRequestIdentifier,
        PurchaseOrderNumber: purchaseOrderNumber,
      },
    }),
    onSuccess: () => {
      onClose();
      navigate(`/portal/po-request-approved/${purchaseOrderApprovalRequestIdentifier}`);
    },
  });

  const {
    handleSubmit, control, formState,
  } = useForm<PurchaseOrderFormValues>();

  const onSubmit = async (formValues: PurchaseOrderFormValues): Promise<void> => {
    await mutateAsync(formValues.PurchaseOrderNumber);
  };

  useEffect(() => {
    const approved = purchaseOrderApprovalRequestQuery.apiResult?.data?.Approved;
    if (approved) {
      navigate(`/portal/po-request-approved/${purchaseOrderApprovalRequestIdentifier}`);
    }
  }, [purchaseOrderApprovalRequestQuery.apiResult?.data, navigate, purchaseOrderApprovalRequestIdentifier]);

  return (
    <QuerySuspense queries={[purchaseOrderApprovalRequestQuery.queryResult]}>
      <Flex
        direction='column'
        align='center'
        mx='auto'
        mt='50px'
      >
        <Box width='400px' padding='20px'>
          <Flex direction='column' gap='25px'>
            <Flex justifyContent='center' mb='15px'>
              <Image src={logoSrc} width='300px' />
            </Flex>
            <Heading fontSize='24px' textAlign='center'>
              Purchase Request
            </Heading>
            <Text>
              Sterling Sound is requesting a Purchase Order for the {purchaseOrderApprovalRequestQuery.apiResult?.data?.ArtistName!} - {purchaseOrderApprovalRequestQuery.apiResult?.data?.ProjectName!} Project
            </Text>
            <Flex>
              <Text mr='7px' fontSize='18px'>
                Amount:
              </Text>
              <Text fontWeight='900' fontSize='18px'>
                {purchaseOrderApprovalRequestQuery.apiResult?.data?.Amount!}
              </Text>
            </Flex>
            <Flex direction='column' gap='15px'>
              <Button onClick={onOpen}>
                <Image w='15px' h='15px' marginRight='7px' src={ApprovePOIcon} />
                Enter purchase order
              </Button>
              <Button variant='back' as={Link} to={purchaseOrderApprovalRequestQuery.apiResult?.data?.DownloadPdfUrl!} target='_blank'>
                <Image w='15px' h='15px' marginRight='7px' src={ViewPOIcon} />
                View request
              </Button>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent
                bg='gray.900'
                border='1px'
                borderColor='gray.600'
                p='15px'
                borderRadius='12px'
              >
                <form>
                  <ModalHeader fontSize='24px' mb='0px' pt='25px' pb='0px'>
                    Purchase Order
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl>
                      <FormLabel>
                        Please enter your company&apos;s purchase order number:
                      </FormLabel>
                      <Controller
                        name='PurchaseOrderNumber'
                        control={control}
                        rules={{ required: 'Purchase order number is required' }}
                        render={({ field, fieldState }) => (
                          <IconInput
                            icon={RequestsIcon}
                            alt='Number Icon'
                            error={fieldState.error}
                          >
                            <Input {...field} placeholder='Company purchase order number' isInvalid={!!fieldState.error} />
                          </IconInput>
                        )}
                      />
                    </FormControl>
                  </ModalBody>

                  <ModalFooter justifyContent='flex-start'>
                    <AsyncButton
                      formState={formState}
                      type='submit'
                      colorScheme='blue'
                      mr={3}
                      width='150px'
                      onClick={handleSubmit(onSubmit)}
                    >Submit
                    </AsyncButton>
                  </ModalFooter>
                </form>
              </ModalContent>
            </Modal>
          </Flex>
        </Box>
      </Flex>
    </QuerySuspense>
  );
}
